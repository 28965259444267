import { useMutation, useQuery } from "@tanstack/react-query";
import { GetCommanWay, ListCommanWay } from "API/commonApi";
import { Img } from "components";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import "react-circular-progressbar/dist/styles.css";
import "video-react/dist/video-react.css";
import "./style.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import UseToast from "utils/AfToast";
import Spinner from "utils/Spinner";
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  LoadProgressBar,
  PlayProgressBar,
  SeekBar,
  FullscreenToggle,
  VolumeMenuButton,
  Slider,
  ProgressControl,
} from "video-react";
import NewsListSingleBlock from "components/NewsListSingleBlock";
import { FacebookShareButton } from "react-share";
import { isMobile } from "react-device-detect";

const SingleNews = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const location: any = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  //console.log(params);

  const [adsData, setAdsData] = useState<any>({
    loaded: false,
    left_in: [],
    right_in: [],
  });
  const [newsList, setNewsList] = useState<any>([]);
  const [nextRelated, setNextRelated] = useState<any>();
  const [showNext, setshowNext] = useState<any>(false);

  const [left_ad_index, set_left_ad_index] = useState<any>(0);
  const [right_ad_index, set_right_ad_index] = useState<any>(0);

  const [vmin, setvmin] = useState(Math.min(window.innerHeight, window.innerWidth));

  const [newsData, setNewsData] = useState<any>({
    loaded: false,
    _subCategory: {},
    _mainCategory: {},
  });

  const [ad1Done, setAd1Done] = useState<boolean>(false);
  const [mainVidDone, setMainVidDone] = useState<boolean>(false);
  const [ad2Done, setAd2Done] = useState<boolean>(false);

  const [ads_timer, setAds_timer] = useState(null);
  useEffect(() => {
    if (ads_timer === 0) {
      adsData.left_in.length > 0 && set_left_ad_index(left_ad_index + 1 >= adsData.left_in.length ? 0 : left_ad_index + 1);
      adsData.right_in.length > 0 && set_right_ad_index(right_ad_index + 1 >= adsData.right_in.length ? 0 : right_ad_index + 1);
      setAds_timer(10);
    }
    if (!ads_timer) return;
    const intervalId = setInterval(() => {
      setAds_timer(ads_timer - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [ads_timer]);

  const [nexttimeLeft, setNextTimeLeft] = useState(null);
  useEffect(() => {
    if (nexttimeLeft === 0) {
      setshowNext(false);
      setNextTimeLeft(null);
    }
    if (!nexttimeLeft) return;
    const intervalId = setInterval(() => {
      setNextTimeLeft(nexttimeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [nexttimeLeft]);

  const [timeLeft, setTimeLeft] = useState(5);
  useEffect(() => {
    if (timeLeft === 0) {
      console.log("TIME LEFT IS 0");
      addAView({ id: params.id });

      adsData["left_in"].forEach((f) => {
        addAdsView({ id: f.id });
      });
      adsData["right_in"].forEach((f) => {
        addAdsView({ id: f.id });
      });

      /*if (adsData["left_in"]?.id) {
        addAdsView({ id: adsData["left_in"].id });
      }
      if (adsData["right_in"]?.id) {
        addAdsView({ id: adsData["right_in"].id });
      }*/
      if (adsData["bottom"]?.id) {
        addAdsView({ id: adsData["bottom"].id });
      }
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    console.log("params chaanges", params.id);
    if (params.id) {
      mutate({ slug: params.id });
    }
  }, [params]);

  const { mutate: addAView } = useMutation((data: any) => GetCommanWay("/admin/news/" + data.id + "/addview", {}, {}), {
    onSuccess: (data: any) => {
      console.log("Added View", data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        //console.log(data.data);
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const { mutate: addAdsView } = useMutation((data: any) => GetCommanWay("/admin/ads/" + data.id + "/addview", {}, {}), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        console.log(data.data);
      }
    },
    onError: (data: any) => {},
  });

  const { mutate: addAdsClick } = useMutation((data: any) => GetCommanWay("/admin/ads/" + data.id + "/addclick", {}, {}), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        console.log(data.data);
      }
    },
    onError: (data: any) => {},
  });

  const { mutate, isLoading: isloading } = useMutation(
    (data: any) => ListCommanWay("/admin/news/list", data, { include: ["_subCategory", "_mainCategory"] }),
    {
      onSuccess: (data: any) => {
        console.log("loaded single news", data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          //UseToast("Data Added Successfully", "success");
          //console.log(data.data);
          setNewsData({ ...data.data.data[0], loaded: true } || {});
          setTimeout(()=>{
            refetchAds();
          },200)
          try {
            relatedNewsMutate({
              data: {
                mainCategory: data.data.data[0]?.mainCategory || [],
              },
            });
          } catch (c) {}
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const { mutate: relatedNewsMutate, isLoading: relatedLoading } = useMutation(
    (data: any) =>
      ListCommanWay("/admin/news/list", data.data, {
        include: ["_subCategory", "_mainCategory"],
        paginate: 3,
      }),
    {
      onSuccess: (data: any) => {
        console.log("loaded related news", data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          //UseToast("Data Added Successfully", "success");
          setNewsList(data.data.data);

          nextRelatedNewsMutate({
            data: {
              id: {
                $gt: newsData.id,
              },
              mainCategory: data.data.data[0]?.mainCategory || [],
            },
          });
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const { mutate: nextRelatedNewsMutate, isLoading: nextRelatedLoading } = useMutation(
    (data: any) =>
      ListCommanWay("/admin/news/list", data.data, {
        include: ["_subCategory", "_mainCategory"],
        paginate: 1,
      }),
    {
      onSuccess: (data: any) => {
        if (data.status == "SUCCESS") {
          try {
            console.log("next related news is: ", data.data.data[0].id);
            setNextRelated(data.data.data[0]);
          } catch (c) {
            setNextRelated(newsList[0]);
          }
        }
      },
      onError: (data: any) => {},
    }
  );

  const {refetch: refetchAds} = useQuery(
    ["adsListAPI"],
    () =>
      ListCommanWay(
        "/admin/ads/list",
        {
          fromDt: { $lte: new Date() },
          toDt: { $gte: new Date() },
          news: params.id,
        },
        { paginate: 10000 }
      ),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess(data) {
        console.log(data.data.data);
        if (data.status == "SUCCESS") {
          let ads = {
            right_in: [],
            left_in: [],
          };
          data.data.data.forEach((f) => {
            if (f.variableName == "left_in" || f.variableName == "right_in") {
              ads[f.variableName].push(f);
            } else {
              ads[f.variableName] = f;
            }
          });
          ads["loaded"] = true;
          setAdsData(ads);
          setAds_timer(10);
          if (!ads["before_vid"]) {
            setAd1Done(true);
          }
        }
      },
    }
  );

  const GetController = () => {
    return (
      <ControlBar>
        <VolumeMenuButton disabled />
        <ReplayControl disabled />
        <ForwardControl disabled />
        <SeekBar disabled />
        <LoadProgressBar disabled />
        <PlayProgressBar disabled />
        <FullscreenToggle disabled />
        <Slider disabled />
        <ProgressControl disabled />
      </ControlBar>
    );
  };
  return (
    <>
      {(isloading || relatedLoading) && <Spinner />}
      <div className="mt-5">
        <div key={newsData.id} className="">
          <div className="relative flex bg-[#FFFCF1] rounded-2xl border border-[#D9D3C5] max-h-[80vh] h-[80vh] md:h-auto">
            <a
              href={adsData["left_in"]?.[left_ad_index]?.link}
              target="_blank"
              className="p-4 md:p-1 flex-1 h-full block"
              onClick={() => {
                addAdsClick({ id: adsData["left_in"]?.[left_ad_index]?.id });
              }}
            >
              <Img
                src={BASE_URL + adsData["left_in"]?.[left_ad_index]?.image}
                className={`rounded-xl mx-auto ${isMobile ? '' : 'h-full'} object-contain`}
                hidden={adsData["left_in"].length == 0}
              />
            </a>

            {adsData.loaded && newsData.loaded ? (
              <>
                {!ad1Done && (
                  <div className="flex-1 flex justify-center">
                    <Player
                      fluid={false}
                      height={isMobile ? '100%' : (vmin * 0.8)}
                      width={vmin * (isMobile ? 0.55 : 0.8)}
                      playsInline
                      autoPlay={true}
                      poster={BASE_URL + newsData?.image}
                      src={BASE_URL + adsData["before_vid"]?.image}
                      onEnded={() => {
                        setAd1Done(true);
                      }}
                      onCanPlay={() => {
                        addAdsView({ id: adsData["before_vid"]?.id });
                      }}
                    >
                      <div className="absolute top-0 right-0 border-2 bg-black-900 px-4 py-2 bg-opacity-40 z-10">
                        <a href={adsData["before_vid"]?.link} target="_blank" className="cursor-pointer underline">
                          Open link
                        </a>
                      </div>
                      <ControlBar disabled>
                        <VolumeMenuButton disabled />
                        <ReplayControl disabled />
                        <ForwardControl disabled />
                        <SeekBar disabled />
                        <LoadProgressBar disabled />
                        <PlayProgressBar disabled />
                        <FullscreenToggle disabled />
                        <Slider disabled />
                        <ProgressControl disabled />
                      </ControlBar>
                    </Player>
                  </div>
                )}
              </>
            ) : (
              <div className="f"></div>
            )}
            {ad1Done && !mainVidDone && (
              <div className=" flex-1 flex justify-center">
                <Player
                  fluid={false}
                  height={isMobile ? '100%' : (vmin * 0.8)}
                  width={vmin * (isMobile ? 0.55 : 0.8)}
                  playsInline
                  autoPlay={true}
                  poster={BASE_URL + newsData?.image}
                  src={BASE_URL + newsData?.video}
                  onError={() => {
                    //console.log("=====main vid can play========", nextRelated)
                  }}
                  onEnded={() => {
                    setMainVidDone(true);
                  }}
                >
                  <ControlBar>
                    <VolumeMenuButton disabled />
                    <ReplayControl disabled />
                    <ForwardControl disabled />
                    <SeekBar disabled />
                    <LoadProgressBar disabled />
                    <PlayProgressBar disabled />
                    <FullscreenToggle disabled />
                    <Slider disabled />
                    <ProgressControl disabled />
                  </ControlBar>
                </Player>
              </div>
            )}
            {mainVidDone && (
              <div className="flex-1 flex justify-center">
                <Player
                  fluid={false}
                  height={isMobile ? '100%' : (vmin * 0.8)}
                  width={vmin * (isMobile ? 0.55 : 0.8)}
                  playsInline
                  autoPlay={true}
                  poster={BASE_URL + newsData?.image}
                  src={BASE_URL + adsData["after_vid"]?.image}
                  onCanPlay={() => {
                    addAdsView({ id: adsData["after_vid"]?.id });
                  }}
                  onError={() => {
                    if (nextRelated) {
                      //navigate("/news/"+nextRelated);
                      navigate((nextRelated.isSeason ? "/season/" : "/news/") + nextRelated.slug);
                      window.location.reload();
                      //console.log("-=-=-=-=-= navigate onErr", nextRelated)
                      //setshowNext(true);
                      setNextTimeLeft(5);
                    }
                  }}
                  onEnded={() => {
                    if (nextRelated) {
                      //navigate("/news/"+nextRelated);
                      navigate((nextRelated.isSeason ? "/season/" : "/news/") + nextRelated.slug);
                      window.location.reload();
                      //console.log("-=-=-=-=-=-=navigate onEnd", nextRelated)
                      //setshowNext(true);
                      setNextTimeLeft(5);

                      setAd1Done(false);
                      setMainVidDone(false);
                    } else {
                      setAd1Done(false);
                      setMainVidDone(false);
                    }
                  }}
                >
                  <div className="absolute top-0 right-0 border-2 bg-black-900 px-4 py-2 bg-opacity-40 z-10">
                    <a href={adsData["after_vid"]?.link} target="_blank" className="cursor-pointer underline">
                      Open link.
                    </a>
                  </div>
                  <ControlBar disabled>
                    <VolumeMenuButton disabled />
                    <ReplayControl disabled />
                    <ForwardControl disabled />
                    <SeekBar disabled />
                    <LoadProgressBar disabled />
                    <PlayProgressBar disabled />
                    <FullscreenToggle disabled />
                    <Slider disabled />
                    <ProgressControl disabled />
                  </ControlBar>
                </Player>
              </div>
            )}

            <a
              className={`p-4 md:p-1 flex-1 sm:min-w-1/4 h-full block ${isMobile ? 'min-h-[220px]' : ''}`}
              href={adsData["right_in"]?.[right_ad_index]?.link}
              target="_blank"
              onClick={() => {
                addAdsClick({ id: adsData["right_in"]?.[right_ad_index]?.id });
              }}
            >
              <Img
                src={BASE_URL + adsData["right_in"]?.[right_ad_index]?.image}
                className={`rounded-xl mx-auto ${isMobile ? '' : 'h-full'} object-contain`}
                hidden={adsData["right_in"].length == 0}
              />
            </a>

            {false && showNext && nextRelated && (
              <div className="shadow-2xl max-w-lg sm:w-full p-3 absolute bg-primary rounded-lg left-1/2 bottom-0 z-20 -translate-x-1/2 flex gap-2">
                <Img src={BASE_URL + nextRelated.image} className="rounded-xl h-36" />
                <div className="flex flex-col">
                  <p className="font-bold text-white underline">{nextRelated.title}</p>
                  <p
                    className="font-medium text-gray-300 mt-3 text-ellipsis overflow-hidden "
                    style={{ display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}
                  >
                    {nextRelated.description}
                  </p>

                  <div className="text-right mt-3">
                    <Link to={(nextRelated.isSeason ? "/season/" : "/news/") + nextRelated.slug}>
                      <span className=" inline-block font-medium bg-light px-4 pb-2 pt-1 rounded-full border border-[#D9D3C5]">
                        Goto Next
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex md:flex-col-reverse justify-between md:items-start items-center mt-6">
            <p className="font-medium text-2xl">{newsData.title}</p>
            <div className="flex items-center gap-5">
              <span className="font-medium uppercase bg-light px-4 py-1 rounded-full">
                {newsData._subCategory ? newsData._subCategory?.name : newsData._mainCategory?.name}
              </span>
              <span className="flex justify-between items-center gap-1 font-medium">
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.9998 20.2697C15.5298 20.2697 18.8198 18.1897 21.1098 14.5897C22.0098 13.1797 22.0098 10.8097 21.1098 9.39973C18.8198 5.79973 15.5298 3.71973 11.9998 3.71973C8.46984 3.71973 5.17984 5.79973 2.88984 9.39973C1.98984 10.8097 1.98984 13.1797 2.88984 14.5897C5.17984 18.1897 8.46984 20.2697 11.9998 20.2697Z"
                    stroke="#3F2E13"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                    fill="#3F2E13"
                    stroke="#3F2E13"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {newsData.views}
              </span>
            </div>
          </div>

          <p className=" text-[#6B6B6B] mt-4">{newsData.description}</p>

          <div className="flex items-center justify-end gap-2 mt-6 ">
            <a className="text-primary font-medium">Share</a>

            <a
              target="_blank"
              className="cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
              }}
            >
              <Img src="/link.png" className="h-7 border border-secondary rounded-full" />
            </a>
            {isMobile ? (
              <a href={`https://api.whatsapp.com/send?text=${window.location.href}`} target="_blank">
                <Img src="/wapp.png" className="h-7 border border-secondary rounded-full" />
              </a>
            ) : (
              <a href={`https://web.whatsapp.com/send?text=${window.location.href}`} target="_blank">
                <Img src="/wapp.png" className="h-7 border border-secondary rounded-full" />
              </a>
            )}
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank">
              <Img src="/fb.png" className="h-7" />
            </a>
            <a href={`https://x.com/intent/tweet?text=${newsData.title} ${window.location.href}`} target="_blank">
              <Img src="/x.png" className="h-7" />
            </a>
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${newsData.title}`} target="_blank">
              <Img src="/linkedin.png" className="h-7" />
            </a>
          </div>

          <a
            className="p-4"
            href={adsData["bottom"]?.link}
            target="_blank"
            onClick={() => {
              addAdsClick({ id: adsData["bottom"]?.id });
            }}
          >
            <Img src={BASE_URL + adsData["bottom"]?.image} className="rounded-xl" hidden={!adsData["bottom"]} />
          </a>

          <div className="flex justify-between items-center mt-6 mb-4">
            <p className="mt-5 font-medium text-2xl">Related news</p>

            <Link to={"/category/" + newsData._mainCategory?.id}>
              <span className="font-medium bg-light px-4 pb-1 pt-0 rounded-full border border-[#D9D3C5]">view more</span>
            </Link>
          </div>
          <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-2 gap-y-6 mb-10">
            {newsList.map((n) => {
              return <NewsListSingleBlock props={n} key={"view-more" + n.id} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default SingleNews;
